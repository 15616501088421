import React from 'react';
import './Ideas.css';
import { useTranslation } from 'react-i18next';
import IonIcon from '@reacticons/ionicons';

const Ideas = () => {
  const { t } = useTranslation();

  const goals = [
    { id: 1, title: t('goal1Title'), description: t('goal1Description'), icon: 'analytics', progress: 90 },
    { id: 2, title: t('goal2Title'), description: t('goal2Description'), icon: 'wifi', progress: 50 },
    { id: 3, title: t('goal3Title'), description: t('goal3Description'), icon: 'logo-apple-appstore', progress: 15 },
    { id: 4, title: t('goal4Title'), description: t('goal4Description'), icon: 'construct', progress: 90 },
  ];

  return (
    <section className="ideas-container fade-in" id="goals-section">
      <h1 className="goalstitle">{t('goalstitle')}</h1>

      <div className="goals">
        {goals.map((goal) => (
          <div
            key={goal.id}
            className="goal-tile"
            style={{ '--progress-width': `${goal.progress}%` }}
          >
            <div className="goal-content">
              <IonIcon size={50} name={goal.icon}></IonIcon>
              <h2 className="goal-title">{goal.title}</h2>
              <p className="goal-description">{goal.description}</p>
            </div>
            <div className="progress-bar">
              <div className="progress-bar-fill"></div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Ideas;
