import React, { useState } from 'react';
import './FAQ.css';
import { useTranslation } from 'react-i18next';

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const { t } = useTranslation();

  const faqs = [
    { question: t('diabcalc_q1'), answer: t('diabcalc_a1') },
    { question: t('diabcalc_q2'), answer: t('diabcalc_a2') },
    { question: t('diabcalc_q3'), answer: t('diabcalc_a3') },
    { question: t('diabcalc_q4'), answer: t('diabcalc_a4') },
    { question: t('diabcalc_q5'), answer: t('diabcalc_a5') },
    { question: t('diabcalc_q6'), answer: t('diabcalc_a6') },
    { question: t('diabcalc_q7'), answer: t('diabcalc_a7') },
    { question: t('diabcalc_q8'), answer: t('diabcalc_a8') },
    { question: t('diabcalc_q9'), answer: t('diabcalc_a9') },
  ];

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="faq fade-in">
  
      <h2>{t('faqtitle')}</h2>
      {faqs.map((faq, index) => (
        <div key={index} className="faq-item">
          <h3 onClick={() => toggleFAQ(index)}>{faq.question}</h3>
          {activeIndex === index && <p>{faq.answer}</p>}
        </div>
      ))}
    </div>
  );
};

export default FAQ; 