import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import './AddProductMealPopup.css';
import { Link } from 'react-router-dom';
import GramsInputPopup from './GramsInputPopup';
import { getApiUrl } from './apiUrl';
import { useParams } from 'react-router-dom';
import { useUser } from './UserContext';
import ReactGA from 'react-ga4'

const AddProductMealPopup = ({ isVisible, onClose, addProductToMeal, user, currentMealId }) => {
  const { t } = useTranslation();
  const [standardRecommendations, setStandardRecommendations] = useState([]);
  const [showGramsPopup, setShowGramsPopup] = useState(false);
  const [currentProduct, setCurrentProduct] = useState('');
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [meals, setMeals] = useState([]);
  const [selectedMeal, setSelectedMeal] = useState(null);
  const apiUrl = getApiUrl();
  const { mealid } = useParams();

  useEffect(() => {
    if (isVisible) {
      fetchMeals().then(() => {
        if (currentMealId) {
          const currentMeal = meals.find(meal => meal.id === parseInt(currentMealId));
          if (currentMeal) {
            setSelectedMeal(currentMeal);
          }
        }
      });
    }
  }, [isVisible, currentMealId, meals]);

  const fetchMeals = async () => {
    ReactGA.event({
      category: 'app_use',
      action: 'addmeal',
      label: ' Added to meal',
    });
    try {
      const response = await axios.get(`${apiUrl}meals/${user.userId}`, {
        headers: {
          'Authorization': `Bearer ${user.token}`,
          'Content-Type': 'application/json'
        },
      });

      if (response.data.success) {
        setMeals(response.data.meals);
        return response.data.meals;
      } else {
        console.error('Failed to fetch meals:', response.data.message);
        return [];
      }
    } catch (error) {
      console.error('An error occurred while fetching meals:', error);
      return [];
    }
  };

  const fetchStandardRecommendations = async () => {
    try {
      const response = await axios.get(`${apiUrl}recommendations`, {
        headers: {
          'Authorization': `Bearer ${user.token}`,
          'Content-Type': 'application/json'
        },
        mode: 'cors'
      });
      if (response.data.success) {
        setStandardRecommendations(response.data.products);
      } else {
        console.error('Failed to fetch standard recommendations:', response.data.message);
      }
    } catch (error) {
      console.error('An error occurred while fetching standard recommendations:', error);
    }
  };

  const handleAddRecommendationToMeal = async (productName, grams, product) => {
    try {
      if (!mealid) {
        console.error('Meal ID not found in URL');
        return;
      }
      const productNutrients = {
        carbohydrates_100g: product.nutriments.carbohydrates,
        proteins_100g: product.nutriments.proteins,
        fats_100g: product.nutriments.fat
      };

      const json = JSON.stringify({ product_name: productName, nutriments: productNutrients });
      const token = user.token;

      const response = await axios.post(`${apiUrl}addProductMeal`, {
        userid: user.userId,
        mealid: parseInt(mealid, 10),
        json: json,
        grams: parseInt(grams, 10),
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        mode: 'cors'
      });

      if (response.data.success) {
        console.log(`Added ${productName} to the meal`);
        addProductToMeal(productName, grams);
        setShowGramsPopup(false);
        onClose();
      } else {
        console.error('Failed to add product to the meal:', response.data.message);
      }
    } catch (error) {
      console.error('An error occurred while adding product to the meal:', error);
    }
  };

  const closePopup = () => {
    setShowGramsPopup(false);
    onClose();
    window.location.reload();
  };

  const openGramsPopup = (productName, product) => {
    setCurrentProduct(productName);
    setSelectedProduct(product);
    setShowGramsPopup(true);
  };

  if (!isVisible) return null;

  return (
    <div className="popup-overlay pop-up">
      <div className="popup-container">
        <div className="popup-header">
          <h2>{t('title')}</h2>
          <button className="close-button" onClick={onClose}>X</button>
        </div>
        <div className="popup-body">
          {standardRecommendations.length > 0 && (
            <div>
              <h3>{t('standardRecommendations')}</h3>
              <div className="recommendations">
                {standardRecommendations.map((product) => (
                  <button
                    key={product.id}
                    className="recommendation-button"
                    onClick={() => openGramsPopup(product.product_name, product)}
                  >
                    {product.product_name}
                  </button>
                ))}
              </div>
            </div>
          )}
          <Link className="standard-button" to="/app/search">
            {t('searchNewProductBtn')}
          </Link>
          <Link className="standard-button" to="/app/scanner">
            {t('scanNewProductBtn')}
          </Link>
          <Link className="standard-button" to="/app/calc">
            {t('addNewProductBtn')}
          </Link>
        </div>
      </div>
      {showGramsPopup && (
        <GramsInputPopup
          productName={currentProduct}
          onClose={() => closePopup()}
          onAdd={handleAddRecommendationToMeal}
          product={selectedProduct}
        />
      )}
    </div>
  );
};

export default AddProductMealPopup;
