import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next'; 
import 'react-toastify/dist/ReactToastify.css';
import { useUser } from './UserContext'; 
import './LoginPanel.css';
import { getApiUrl } from './apiUrl';
import IonIcon from '@reacticons/ionicons';

const LoginPanel = () => {
  const { login } = useUser();
  const { t } = useTranslation();
  const apiUrl = getApiUrl();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = async () => {
    if (!username || !password) {
      toast.error(t('enterUsernameAndPassword'));
      return;
    }

    try {
      const response = await axios.post(`${apiUrl}login`, { username, password }, {
        withCredentials: true,
      });

      if (response.data.success) {
        toast.success(t('loginSuccess'));
        login(response.data.userId, response.data.token);
        navigate('/app');
      } else {
        toast.error(t('loginError'));
      }
    } catch (error) {
      toast.error(t('loginErrorConnection'));
    }
  };

  return (
    <div className='panel'>
      <div className="overlay"></div>
      <div className='modal'>
        <h1>{t('login')}</h1><hr />
        <label>{t('email')}: <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} /></label>
        <label>{t('password')}: <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} /></label>
        <button onClick={handleLogin}>{t('login')}</button>
        <hr />
        <p>{t('noAccount')} <Link className="change" to="/app/register">{t('registerHere')}</Link>.</p>
      </div>
    </div>
  );
};

export default LoginPanel;
