import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { getApiUrl } from './apiUrl'; // Upewnij się, że masz tę funkcję
import './SharedProductDetails.css'; // Importuj plik CSS
import IonIcon from '@reacticons/ionicons';
import { useUser } from './UserContext';

const ShareMeal = () => {
  const { mealId } = useParams(); // Odczytujemy mealId z linku
  const apiUrl = getApiUrl();
  const [mealName, setMealName] = useState('');
  const [mealInstructions, setMealInstructions] = useState('');
  const [isCopying, setIsCopying] = useState(false);
  const { user } = useUser(); // Uzyskaj kontekst użytkownika

  useEffect(() => {
    const fetchMealDetails = async () => {
      try {
        const response = await axios.get(`${apiUrl}mealName/${mealId}`); // Użyj endpointu do pobierania szczegółów posiłku
        
        if (response.data.success) {
          setMealName(response.data.mealName);
     
        } else {
          toast.error('Nie udało się pobrać szczegółów posiłku.');
        }
      } catch (error) {
        console.error('Błąd podczas pobierania szczegółów posiłku:', error);
        toast.error('Wystąpił błąd podczas pobierania szczegółów posiłku.');
      }
    };

    fetchMealDetails();
  }, [mealId, apiUrl, user.userId]);

  const handleCopyMeal = async () => {
    if (!user || !user.userId) { // Sprawdź, czy użytkownik jest zalogowany
      toast.error('Musisz być zalogowany, aby skopiować posiłek.');
      return;
    }

    setIsCopying(true);
    try {
    

      const response = await axios.post(`${apiUrl}copyMeal/${mealId}/${user.userId}`, {
        headers: {
            'Authorization': `Bearer ${user.token}`,
            'Content-Type': 'application/json',
          },
      });

      if (response.data.success) {
        toast.success('Posiłek został skopiowany!');
      } else {
        toast.error('Nie udało się skopiować posiłku.');
      }
    } catch (error) {
      console.error('Błąd podczas kopiowania posiłku:', error);
      toast.error('Wystąpił błąd podczas kopiowania posiłku.');
    } finally {
      setIsCopying(false);
    }
  };

  return (
    <div className="product-container">
      <header className="shared-header">Użytkownik udostępnił Ci posiłek!</header>
      <div className="product-details">
        <h1 className="product-name-shared">{mealName}</h1>
        <p><strong>Instrukcje:</strong> {mealInstructions}</p>
        <button className="open-web-btn-shared" onClick={handleCopyMeal} disabled={isCopying}>
          {isCopying ? 'Kopiowanie...' : 'Skopiuj posiłek do mojej aplikacji'}
          <IonIcon name="copy-outline" className="icon" />
        </button>
      </div>
    </div>
  );
};

export default ShareMeal; 