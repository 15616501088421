import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './AddProductMealPopup.css';
import GramsInputPopupFromS from './GramsInputPopupFromS';
import { getApiUrl } from './apiUrl';
import axios from 'axios';
import { toast } from 'react-toastify';
import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router-dom';
import { useMeal } from './MealContext';

const AddMealPPopup = ({ isVisible, onClose, addProductToMeal, user, product }) => {
  const { t } = useTranslation();
  const [showGramsPopup, setShowGramsPopup] = useState(false);
  const [selectedMeal, setSelectedMeal] = useState(null);
  const [meals, setMeals] = useState([]);
  const apiUrl = getApiUrl();
  const navigate = useNavigate();
  const { currentMeal } = useMeal(); // Replace the direct localStorage access with context

  useEffect(() => {
    if (isVisible) {
      const initializePopup = async () => {
        await fetchMeals(); // Pobranie listy posiłków
      };
      initializePopup();
    }
  }, [isVisible]);
  
  // Synchronizacja selectedMeal z currentMeal
  useEffect(() => {
    if (isVisible && meals.length > 0 && currentMeal?.id) {
      const mealToSelect = meals.find(m => m.id === parseInt(currentMeal.id));
      if (mealToSelect) {
        console.log('Setting meal from context:', mealToSelect.mealname);
        setSelectedMeal(mealToSelect);
      }
    }
  }, [isVisible, meals, currentMeal]);
  

  const fetchMeals = async () => {
    ReactGA.event({
      category: 'app_use',
      action: 'addmeal',
      label: 'Added to meal',
    });

    try {
      const response = await axios.get(`${apiUrl}meals/${user.userId}`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.data.success) {
        setMeals(response.data.meals);
        if (currentMeal?.id) {
          const mealToSelect = response.data.meals.find((m) => m.id === parseInt(currentMeal.id));
          setSelectedMeal(mealToSelect || null);
        }
      } else {
        toast.error(t('error.fetchMealsFailed'));
      }
    } catch (error) {
      console.error('Error fetching meals:', error);
      toast.error(t('error.fetchMealsError'));
    }
  };

  const handleAddProductToMeal = async (grams) => {
    try {
      if (!selectedMeal) {
        toast.error(t('error.noMealSelected'));
        return;
      }

      const productNutrients = {
        carbohydrates_100g: product.carbohydrates_per100 || product.nutriments?.carbohydrates_100g || product.nutriments?.carbohydrates,
        proteins_100g: product.nutriments?.proteins || product.protein_per100 || product.nutriments?.proteins_100g,
        fats_100g: product.nutriments?.fat || product.fat_per100 || product.nutriments?.fats_100g,
      };

      const json = JSON.stringify({
        product_name: product.product_name || product.name || t('unknownProduct'), // Ensure product name is correctly set
        nutriments: productNutrients,
      });

      const response = await axios.post(`${apiUrl}addProductMeal`, {
        userid: parseInt(user.userId, 10),
        mealid: parseInt(selectedMeal.id, 10),
        json: json,
        grams: parseInt(grams, 10),
      }, {
        headers: {
          Authorization: `Bearer ${user.token}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.data.success) {
        addProductToMeal(product.product_name || product.name, grams); // Ensure product name is correctly passed
        setShowGramsPopup(false);
        toast.success(t('addedToMeal', { product: product.product_name || product.name }));
        onClose();
        navigate(`/app/meal/${selectedMeal.id}`);
      } else {
        toast.error(t('error.addProductFailed'));
      }
    } catch (error) {
      console.error('Error adding product to the meal:', error);
      toast.error(t('error.addProductError'));
    }
  };

  const openGramsPopup = () => {
    setShowGramsPopup(true);
  };

  if (!isVisible) return null;

  return (
    <div className="popup-overlay pop-up">
      <div className="popup-container">
        <div className="popup-header">
          <h2>{t('addProduct.title')}</h2>
          <button
            className="close-button"
            onClick={onClose}
            aria-label={t('close')}
          >
            X
          </button>
        </div>
        <div className="popup-body">
          <div>
            <h3>{t('selectMeal.title')}</h3>
            <select
  value={selectedMeal?.id || ''}
  onChange={(e) => {
    const selected = meals.find((meal) => meal.id === parseInt(e.target.value));
    setSelectedMeal(selected);
    console.log('Manual meal selection:', selected?.mealname);
  }}
>
  <option value="">{t('selectMeal.placeholder')}</option>
  {meals.map((meal) => (
    <option key={meal.id} value={meal.id}>
      {meal.mealname} {currentMeal && meal.id === currentMeal.id ? ' (aktualny)' : ''}
    </option>
  ))}
</select>

          </div>
          <button className="standard-button" onClick={openGramsPopup}>
            {t('addProduct.button')}
          </button>
        </div>
      </div>
      {showGramsPopup && (
        <GramsInputPopupFromS
          productName={product.name}
          onClose={() => setShowGramsPopup(false)}
          onAdd={handleAddProductToMeal}
          product={product}
        />
      )}
    </div>
  );
};

export default AddMealPPopup;
