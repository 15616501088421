import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import IonIcon from "@reacticons/ionicons";
import './ProductDetails.css';
import noimage from "./noimage.jpg";
import AddMealPPopup from './AddToMealPopup';
import BugReport from './BugReport';
import { useUser } from './UserContext';
import { toast } from 'react-toastify';
import axios from 'axios';
import { getApiUrl } from './apiUrl';
import ReactGA from 'react-ga4';
import './SearchView.css';

const ProductDetails = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user } = useUser();
  const product = state?.product;
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isBugReportOpen, setIsBugReportOpen] = useState(false);
  const apiUrl = getApiUrl();
  const [selectedPortion, setSelectedPortion] = useState('100');
  const [customPortion, setCustomPortion] = useState('');
  const [portions, setPortions] = useState([
    { value: 'custom', label: t('customPortion') },
    { value: '100', label: '100g' }
  ]);
  const [showCustomInput, setShowCustomInput] = useState(false);

  useEffect(() => {
    const newPortions = [{ value: 'custom', label: t('customPortion') }, { value: '100', label: '100g' }];
    
    console.log('Product data:', product); // Debug całego produktu

    // Sprawdzamy, czy to produkt z USDA (Food Data Central)
    if (product?.dataType === 'Survey (FNDDS)' || product?.dataType === 'SR Legacy' || product?.foodClass === 'FinalFood') {
      console.log('USDA Food Data Central product detected');
      
      if (product?.foodPortions && Array.isArray(product.foodPortions)) {
        product.foodPortions.forEach(portion => {
          console.log('Processing USDA portion:', portion);

          if (portion && portion.gramWeight) {
            const portionLabel = portion.portionDescription
              ? `${portion.amount || 1} ${portion.portionDescription} (${portion.gramWeight}g)`
              : `${portion.gramWeight}g`;

            newPortions.push({
              value: portion.gramWeight.toString(),
              label: portionLabel
            });
          }
        });
      }
    } else {
      // Standardowa obsługa dla innych źródeł danych
      if (product?.portions && Array.isArray(product.portions)) {
        product.portions.forEach(portion => {
          if (portion && portion.gramWeight) {
            newPortions.push({
              value: portion.gramWeight.toString(),
              label: portion.portionDescription 
                ? `${portion.portionDescription} (${portion.gramWeight}g)`
                : `${portion.gramWeight}g`
            });
          }
        });
      }

      if (product?.serving_size) {
        newPortions.push({
          value: product.serving_size.toString(),
          label: `${product.serving_description || 'Porcja'} (${product.serving_size}g)`
        });
      }
    }

    console.log('Final portions:', newPortions);
    setPortions(newPortions);
  }, [product]);

  useEffect(() => {
    // Sprawdzamy, czy produkt pochodzi ze skanera (sprawdzając strukturę danych)
    const isFromScanner = product && 
      (product.carbohydrates_per100 !== undefined || 
       product.protein_per100 !== undefined || 
       product.fat_per100 !== undefined);

    if (isFromScanner) {
      const handleBackButton = (e) => {
        e.preventDefault();
        navigate('/app/scanner');
      };

      // Dodajemy nasłuchiwanie przycisku wstecz
      window.addEventListener('popstate', handleBackButton);

      // Czyszczenie nasłuchiwania
      return () => {
        window.removeEventListener('popstate', handleBackButton);
      };
    }
  }, [product, navigate]);

  const calculateCU = (carbohydrates) => {
    if (!carbohydrates || isNaN(carbohydrates)) return 0;
    return parseFloat((carbohydrates / 10).toFixed(1));
  };

  const calculateFPU = (protein, fat) => {
    if (!protein || !fat || isNaN(protein) || isNaN(fat)) return 0;
    const multipliedProtein = protein * 4;
    const multipliedFat = fat * 9;
    return parseFloat((multipliedProtein + multipliedFat) / 100).toFixed(1);
  };

  const calculatePerServing = (per100g, servingSize) => {
    if (!per100g || !servingSize) return 0;
    const servingSizeInGrams = parseFloat(servingSize);
    const value = parseFloat(per100g);
    
    if (isNaN(servingSizeInGrams) || isNaN(value)) return 0;
    return (value * servingSizeInGrams) / 100;
  };

  const formatNumber = (number) => {
    if (typeof number === 'number' && isFinite(number)) {
      return number.toFixed(1);
    }
    return '0.0';
  };

  const generateRandomNumber = () => Math.floor(Math.random() * 1000000);

  const addToMeal = async (user, product) => {
    if (!user) {
      toast.error(t('mustBeLoggedInToAddProduct'));
      navigate('/app/login');
      return;
    }

    try {
      const productId = generateRandomNumber();
      const response = await axios.post(
        `${apiUrl}addProductToList`,
        {
          userId: user.userId,
          productId: productId,
          productJson: JSON.stringify({
            product_name: product.product_name || product.name,
            barcode: product.barcode || '',
            nutriments: {
              carbohydrates_100g: product.nutriments?.carbohydrates || 0,
              proteins_100g: product.nutriments?.proteins || 0,
              fats_100g: product.nutriments?.fat || 0,
            },
          }),
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
          withCredentials: true,
        }
      );

      if (response.data.success) {
        toast.success(`${t('saved')} ${product.product_name || product.name} ${t('saved2')}`);
      } else {
        toast.error(`${t('errorOccurred')}: ${response.data.message}`);
      }
    } catch (error) {
      console.error(error);
      toast.error(t('errorSaving'));
    }
  };

  const handleOpenPopup = () => {
    if (user) {
      setIsPopupVisible(true);
    } else {
      toast.error(t('mustBeLoggedInToAddProduct'));
      navigate('/app/login');
    }
  };

  const handleBugReport = () => {
    setIsBugReportOpen(true);
  };

  const handleOpenPopupBug = (product) => {
    setIsBugReportOpen(true);
  };

  const addToList = async () => {
    if (!user) {
      toast.error(t('mustBeLoggedInToAddProduct'));
      navigate('/app/login');
      return;
    }

    ReactGA.event({
      category: 'app_use',
      action: 'addmeal',
      label: 'Added to meal from details',
    });

    try {
      const productId = Math.floor(Math.random() * 1000000);
      const response = await axios.post(
        `${apiUrl}addProductToList`,
        {
          userId: user.userId,
          productId: productId,
          productJson: JSON.stringify({
            product_name: product.product_name || product.name,
            barcode: product.barcode || '',
            nutriments: {
              carbohydrates_100g: product.nutriments?.carbohydrates || 0,
              proteins_100g: product.nutriments?.proteins || 0,
              fats_100g: product.nutriments?.fat || 0,
            },
          }),
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
          withCredentials: true,
        }
      );

      if (response.data.success) {
        toast.success(`${t('saved')} ${product.product_name || product.name} ${t('saved2')}`);
      } else {
        toast.error(`${t('errorOccurred')}: ${response.data.message}`);
      }
    } catch (error) {
      console.error(error);
      toast.error(t('errorSaving'));
    }
  };

  const addProductToMeal = async (product, grams, mealId) => {
    if (!user) {
      toast.error(t('mustBeLoggedInToAddProduct'));
      navigate('/app/login');
      return;
    }

    try {
      const response = await axios.post(
        `${apiUrl}addProductMeal`,
        {
          userId: user.userId,
          mealId: mealId,
          json: {
            product_name: product.product_name || product.name,
            nutriments: {
              carbohydrates: product.nutriments?.carbohydrates || 0,
              proteins: product.nutriments?.proteins || 0,
              fat: product.nutriments?.fat || 0,
            },
            grams: grams,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
          withCredentials: true,
        }
      );

      if (response.data.success) {
        toast.success(t('productAddedToMeal'));
        setIsPopupVisible(false);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error('Error adding product to meal:', error);
      toast.error(t('errorAddingProductToMeal'));
    }
  };

  const getGlutenStatus = (status) => {
    switch (status) {
        case 1:
            return { text: t('bezglutenowy'), className: 'gluten-free' }; // Bezglutenowy
        case 2:
            return { text: t('brakOznaczenia'), className: 'uncertain' }; // Brak oznaczenia
        case 3:
            return { text: t('glutenowy'), className: 'contains-gluten' }; // Glutenowy
        default:
            return { text: t('brakInformacji'), className: '' }; // Brak informacji
    }
  };
  
  const handlePortionChange = (e) => {
    const value = e.target.value;
    setSelectedPortion(value);
    if (value === 'custom') {
      setShowCustomInput(true);
    } else {
      setShowCustomInput(false);
      setCustomPortion('');
    }
  };

  const handleCustomPortionChange = (e) => {
    const value = e.target.value;
    if (value === '' || /^\d*\.?\d*$/.test(value)) {
      setCustomPortion(value);
      if (value !== '') {
        setSelectedPortion(value);
      }
    }
  };

  const handleGoBack = () => {
    // Sprawdź, czy istnieje historia nawigacji
    if (window.history.length > 1) {
        navigate(-1); // Przejdź do poprzedniego widoku
    } else {
        navigate('/app/search'); // Jeśli nie ma historii, przejdź do widoku wyszukiwania
    }
  };

  if (!product || !product.nutriments) {
    return (
      <div className="error-container">
        <h2>{t('productNotFound')}</h2>
        <button onClick={() => navigate(-1)}>{t('goBack')}</button>
      </div>
    );
  }

  return (
    <div className='panel'>
    <div className="modal">
      <button className="back-button" onClick={handleGoBack}>
        <IonIcon name="arrow-back" /> {t('goBack')}
      </button>

      <div className="product-details-content">
        <div className="product-main-info">
          {product.is_gluten_free !== undefined && (
            <div className={`gluten-badge ${getGlutenStatus(product.is_gluten_free).className}`}>
              {product.is_gluten_free === 1 && (
                <IonIcon name="checkmark-circle-outline" />
              )}
              {product.is_gluten_free === 2 && (
                <span className="uncertain-icon">{getGlutenStatus(product.is_gluten_free).text}</span>
              )}
              {product.is_gluten_free === 3 && (
                <IonIcon name="close-circle-outline" />
              )}
              {product.is_gluten_free !== 2 && getGlutenStatus(product.is_gluten_free).text}
            </div>
          )}
          
          <h1>{product.product_name || t('noname')}</h1>
          <div className="product-image-container">
            {product.image_url ? (
              <img 
                src={product.image_url} 
                alt={product.product_name}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = noimage;
                }}
              />
            ) : (
              <div className="no-image-placeholder">
                <img src={noimage} alt="No image available" />
              </div>
            )}
          </div>

          <div className="action-buttons">
            <button className="primary-btn" onClick={() => addToMeal(user, product)}>
              <IonIcon name="bookmark" /> {t('save')}
            </button>
            <button className="secondary-btn" onClick={() => handleOpenPopup(product)}>
              <IonIcon name="restaurant" /> {t('addToMeal')}
            </button>
            <button className="secondary-btn" onClick={() => handleOpenPopupBug(product)}>
              <IonIcon name="bug-outline" /> {t('reportBug')}
            </button>
            {product.urlRecipe && (
              <a 
                className="recipe-btn" 
                href={product.urlRecipe} 
                target="_blank" 
                rel="noopener noreferrer"
              >
                <IonIcon name="book" /> {t('viewRecipe')}
              </a>
            )}
          </div>

        </div>

        <div className="product-info-sections">
          <section className="nutrition-section">
            <h2>{t('nutritionalInformation')}</h2>
            <div className="energy-info">
              <p>
                <IonIcon name="fast-food-outline" />
                {t('energy')} 
                {product.nutriments?.["energy-kcal"] ? `${product.nutriments["energy-kcal"]} kcal` : '0'} / 
                {product.nutriments?.["energy-kj"] ? `${product.nutriments["energy-kj"]} kj` : '0'}
              </p>
            </div>

            <div className="portion-selector">
              <label htmlFor="portion">{t('selectPortion')}</label>
              <div className="portion-controls">
                {!showCustomInput ? (
                  <>
                    <select 
                      id="portion" 
                      value={selectedPortion} 
                      onChange={handlePortionChange}
                    >
                      <option value="100">100g</option>
                      {portions.map((portion) => (
                        portion.value !== 'custom' && (
                          <option key={portion.value} value={portion.value}>
                            {portion.label}
                          </option>
                        )
                      ))}
                    </select>
                    <button 
                      className="custom-portion-btn"
                      onClick={() => {
                        setShowCustomInput(true);
                        setSelectedPortion('custom');
                      }}
                    >
                      {t('customPortion')}
                    </button>
                  </>
                ) : (
                  <div className="custom-portion">
                    <input
                      type="number"
                      placeholder={t('enterCustomGrams')}
                      value={customPortion}
                      onChange={handleCustomPortionChange}
                      aria-label={t('customPortion')}
                      autoFocus
                    />
                    <span>g</span>
                    <button 
                      className="cancel-custom-btn"
                      onClick={() => {
                        setShowCustomInput(false);
                        setSelectedPortion('100');
                        setCustomPortion('');
                      }}
                    >
                      ✕
                    </button>
                  </div>
                )}
              </div>
            </div>

            <table className="nutrition-table">
              <thead>
                <tr>
                  <th>{t('nutritionalValue')}</th>
                  <th>{t('per')} {selectedPortion || '100'}g</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{t('calories')}</td>
                  <td>{formatNumber(calculatePerServing(product.nutriments?.["energy-kcal"] || 0, selectedPortion))}</td>
                </tr>
                <tr>
                  <td>{t('carbohydrates')}</td>
                  <td>{formatNumber(calculatePerServing(product.nutriments?.carbohydrates || 0, selectedPortion))}</td>
                </tr>
                <tr>
                  <td>{t('protein')}</td>
                  <td>{formatNumber(calculatePerServing(product.nutriments?.proteins || 0, selectedPortion))}</td>
                </tr>
                <tr>
                  <td>{t('fat')}</td>
                  <td>{formatNumber(calculatePerServing(product.nutriments?.fat || 0, selectedPortion))}</td>
                </tr>
                <tr>
                  <td><IonIcon name="flash" /> {t('exchange')}</td>
                  <td>{calculateCU(calculatePerServing(product.nutriments?.carbohydrates || 0, selectedPortion))}</td>
                </tr>
                <tr>
                  <td><IonIcon name="flash-outline" /> {t('fpu')}</td>
                  <td>{calculateFPU(
                    calculatePerServing(product.nutriments?.proteins || 0, selectedPortion),
                    calculatePerServing(product.nutriments?.fat || 0, selectedPortion)
                  )}</td>
                </tr>
              </tbody>
            </table>
          </section>

          {/* Only show ingredients section if ingredients exist */}
          {product.ingredients && (
            <section className="ingredients-section">
              <h2>{t('ingredients')}</h2>
              <p>{product.ingredients}</p>
            </section>
          )}
   {product.info && (
          <section className="allergens-section">
            <h2>{t('info')}</h2>
            <p>{product.info || t('noAllergensInformation')}</p>
          </section>  )}
        </div>
      </div>
    </div>

    <AddMealPPopup
        isVisible={isPopupVisible}
        onClose={() => setIsPopupVisible(false)}
        addProductToMeal={(product, grams) => {
          // Funkcja do dodawania produktu do posiłku
          console.log(`Dodano ${product} z ${grams} gramami`);
        }}
        user={user}
       
        product={product} // Przekazujesz aktualnie wybrany produkt
      />  

    <BugReport 
      isOpen={isBugReportOpen}
      onClose={() => setIsBugReportOpen(false)}
      isScanning={false}
      scannedBarcode={product}
    />
    </div>
  );
};

export default ProductDetails;

