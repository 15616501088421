// Media.js
import React from 'react';
import { useTranslation } from 'react-i18next'; 
import { Link } from 'react-router-dom';
import './Details.css';
import IonIcon from '@reacticons/ionicons';
import ImageBurzua from './files/burzua.png'
import ImageMotywacja from './files/motywacja.png'
const Influencers = () => {
  const { t } = useTranslation();

  
   


  return (
    <div className="influencer-section">
        <h1 className="details-vc-title">{t('titlehelpus')}      <IonIcon name='heart' /></h1>
      <p className='details-vc-description'>{t('helpusdescpopup')}</p>

      <br></br>
      <iframe frameborder="0" className='iframe' width="430" height="500" scrolling="no" src="https://pomagam.pl/d9pw8e/widget/large"></iframe>
    </div>
  );
};

export default Influencers;
