import React, { useRef, useState, useCallback } from 'react';
import Webcam from 'react-webcam';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';
import { getApiUrl } from './apiUrl';
import './ImageAnalyzer.css';
import IonIcon from '@reacticons/ionicons';
import CameraInterface from './CameraInterface';
import scanningSoundGoodFile from './scansoundgoodai.mp3';
import scanningSoundBadFile from './scansoundbad.mp3';
import scanningSoundQuestionFile from './scansoundquestionai.mp3';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { PieChart, Pie, Cell, Tooltip, LabelList } from 'recharts';
import { useUser } from './UserContext';

// Constants for video constraints
const videoConstraints = {
  width: { min: window.innerHeight },
  height: { min: window.innerWidth },
  facingMode: 'environment',
};

// LoadingDots component
const LoadingDots = () => (
  <div className="loading-dots">
    <div className="dot"></div>
    <div className="dot"></div>
    <div className="dot"></div>
  </div>
);

const COLORS = ['#FF8042', '#0088FE', '#00C49F'];

const AnalysisPopup = ({ analysisResult, question, onResponseSubmit, onCorrectionSubmit, onClose }) => {
  const { t } = useTranslation();
  const [userResponse, setUserResponse] = useState('');
  const [loading, setLoading] = useState(false);
  const [corrections, setCorrections] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const { user } = useUser();

  const handleResponseChange = (event) => {
    setUserResponse(event.target.value);
  };

  const handleCorrectionChange = (index, field, value) => {
    setCorrections((prevCorrections) => ({
      ...prevCorrections,
      [`${index}_${field}`]: value
    }));
  };

  const handleResponseSubmit = () => {
    setLoading(true);
    onResponseSubmit(userResponse)
      .finally(() => {
        setLoading(false);
        setUserResponse('');
      });
  };

  const handleCorrectionSubmit = () => {
    setLoading(true);
    onCorrectionSubmit(corrections)
      .finally(() => {
        setLoading(false);
      });
  };

  const calculateCU = (carbohydrates) => {
    return carbohydrates ? parseFloat((carbohydrates / 10).toFixed(1)) : null;
  };

  const calculateFPU = (protein, fat) => {
    let multipliedProtein = protein * 4;
    let multipliedFat = fat * 9;
    return parseFloat((multipliedProtein + multipliedFat) / 100).toFixed(1);
  };
  
  const totals = analysisResult?.reduce(
    (acc, item) => {
      acc.carbs += item.carbs;
      acc.proteins += item.proteins;
      acc.fats += item.fats;
      acc.count += 1;
      return acc;
    },
    { carbs: 0, proteins: 0, fats: 0, count: 0 }
  );

  // Add chart data calculation
  const chartData = [
    { name: t('carbs'), value: totals?.carbs || 0 },
    { name: t('proteins'), value: totals?.proteins || 0 },
    { name: t('fats'), value: totals?.fats || 0 }
  ].filter(item => item.value > 0);

  const handleSaveMeal = async () => {
    setIsSaving(true);
    try {
      const userId = user.userId;
      const token = user.token; // Get token from localStorage
      
      if (!userId) {
        toast.error(t('pleaseLogin'));
        return;
      }

      const mealData = {
        name: 'AI Generated Meal',
        items: analysisResult.map(item => ({
          ingredient: item.ingredient,
          weight: item.weight || 100,
          carbs: item.carbs,
          proteins: item.proteins,
          fats: item.fats
        }))
      };

      const response = await axios.post(`${getApiUrl()}saveAIMeal`, 
        {
          mealData,
          userId
        },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );

      if (response.data.success) {
        setIsSaved(true);
        toast.success(t('mealSaved'));
      } else {
        toast.error(t('saveMealError'));
      }
    } catch (error) {
      console.error('Error saving meal:', error);
      if (error.response?.status === 401) {
        toast.error(t('sessionExpired'));
      } else {
        toast.error(t('saveMealError'));
      }
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="analysis-popup">
      <button className="close-button" onClick={onClose}>X</button>
      {analysisResult.length > 0 ? (
        <div className="meal-details-content">
           <div className="save-meal-section">
            {!isSaved ? (
              <button 
                className="save-meal-button" 
                onClick={handleSaveMeal}
                disabled={isSaving}
              >
                {isSaving ? <LoadingDots /> : t('saveMeal')}
              </button>
            ) : (
              <div className="saved-indicator">
                <IonIcon name="checkmark-circle" className="saved-icon" />
                {t('mealSaved')}
              </div>
            )}
          </div>
          <div className="meal-details-chart">
            <PieChart width={300} height={300}>
              <Pie
                data={chartData}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                innerRadius={60}
                outerRadius={80}
                fill="#8884d8"
                paddingAngle={5}
              >
                {chartData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
                <LabelList dataKey="name" position="outside" />
              </Pie>
              <Tooltip />
            </PieChart>
          </div>

          <section className="meal-summary-section">
            <div className="meal-summary-table">
              <table>
                <thead>
                  <tr>
                    <th>{t('nutrients')}</th>
                    <th>{t('amount')}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{t('carbs')}</td>
                    <td>{totals?.carbs || 0} g</td>
                  </tr>
                  <tr>
                    <td>{t('proteins')}</td>
                    <td>{totals?.proteins || 0} g</td>
                  </tr>
                  <tr>
                    <td>{t('fats')}</td>
                    <td>{totals?.fats || 0} g</td>
                  </tr>
                  <tr>
                    <td>
                      <IonIcon name='flash' className='text' size={20} /> {t('WW')}
                    </td>
                    <td>{calculateCU(totals?.carbs) || "0.0"}</td>
                  </tr>
                  <tr>
                    <td>
                      <IonIcon name='flash-outline' className='text' size={20} /> {t('fpu')}
                    </td>
                    <td>{calculateFPU(totals?.proteins, totals?.fats) || "0.0"}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </section>

          <section className="meal-details-products">
            <h2>{t('ingredients')}</h2>
            {analysisResult.map((item, index) => (
              <div key={index} className="product-item-dt pop-up">
                <h2 className="product-name-dt">{item.ingredient}</h2>
                <table className="product-info-table">
                  <tbody>
                    <tr>
                      <td>{t('carbs')}:</td>
                      <td>{item.carbs} g</td>
                    </tr>
                    <tr>
                      <td>{t('proteins')}:</td>
                      <td>{item.proteins} g</td>
                    </tr>
                    <tr>
                      <td>{t('fats')}:</td>
                      <td>{item.fats} g</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ))}
          </section>
          
          <div className="ai-disclaimer">
            <p><IonIcon name='hardware-chip-outline' className='text-search' /> {t('AIdislaimer')}</p>
          </div>

         
        </div>
      ) : (
        question && (
          <div className="question-section">
            <h3>{t('questionFromAssistant')}</h3>
            <p>{question}</p>
            <textarea
              value={userResponse}
              onChange={handleResponseChange}
              placeholder={t('enterYourAnswer')}
            />
            <button onClick={handleResponseSubmit} disabled={loading}>
              {loading ? <LoadingDots /> : t('submit')}
            </button>
          </div>
        )
      )}
    </div>
  );
};

const ImageAnalyzer = () => {
  const { t } = useTranslation();
  const webcamRef = useRef(null);
  const [capturedImage, setCapturedImage] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [analysisResult, setAnalysisResult] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [question, setQuestion] = useState(null);
  const [scanningSoundGood, setScanningSoundGood] = useState(new Audio(scanningSoundGoodFile));
  const [scanningSoundBad, setScanningSoundBad] = useState(new Audio(scanningSoundBadFile));
  const [scanningSoundQuestion, setScanningSoundQuestion] = useState(new Audio(scanningSoundQuestionFile));
  const [selectedImage, setSelectedImage] = useState(null); 
  const navigate = useNavigate();

  // New function to handle file selection
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
        uploadImage(reader.result); // Upload the selected image
      };
      reader.readAsDataURL(file); // Convert file to a data URL
    }
  };

  const captureImage = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    if (imageSrc) {
      setCapturedImage(imageSrc);
      uploadImage(imageSrc);
    }
  }, [webcamRef]);

  const uploadImage = async (imageSrc) => {
    if (!imageSrc) {
        toast.error(t('noImageCaptured'));
        scanningSoundBad.play();
        return;
    }

    setIsUploading(true);
    setErrorMessage('');

    const formData = new FormData();
    formData.append('image', dataURItoBlob(imageSrc), 'image.png');

    try {
        const apiUrl = `${getApiUrl()}analyze-image`;
        const response = await axios.post(apiUrl, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        if (response.status === 200) {
            const { analysis } = response.data;

            // Set question and analysisResult based on the response
            setQuestion(analysis.analysis.question || ""); // Ensure the question is set
            setAnalysisResult(analysis.items || []); // Ensure analysis items are set

            setShowPopup(true); // Show the popup with results
        } else {
            toast.error(t('analysisFailed'));
            setErrorMessage(t('analysisFailed'));
            scanningSoundBad.play();
        }
    } catch (error) {
        console.error(error);
        toast.error(t('serverError'));
        setErrorMessage(t('serverError'));
        scanningSoundBad.play();
    } finally {
        setIsUploading(false);
    }
  };

  const handleResponseSubmit = async (response) => {
    try {
        const apiUrl = `${getApiUrl()}respond-query`;
        const result = await axios.post(apiUrl, { userResponse: response });

        if (result.status === 200) {
            const { analysis } = result.data;

            // Check if the response indicates success
            if (result.data.success) {
                // Set question and items correctly based on the response
                setQuestion(analysis.analysis.question || ""); // Ensure question is set
                setAnalysisResult(analysis.items || []); // Ensure items are set

                setShowPopup(true); // Show the popup
                toast.success(t('responseSubmitted'));
            } else {
                toast.error(t('responseSubmissionFailed'));
            }
        } else {
            toast.error(t('responseSubmissionFailed'));
        }
    } catch (error) {
        console.error(error);
        toast.error(t('responseSubmissionFailed'));
    }
  };

  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  const closePopup = () => {
    setShowPopup(false);
    setCapturedImage(null);
    setAnalysisResult(null);
    setErrorMessage('');
    setQuestion(null);
  };

  const handleTopRightButtonClick = (navigate) => {
    navigate('/');
  };

  const handleCorrectionSubmit = async (corrections) => {
    try {
      const apiUrl = `${getApiUrl()}submit-corrections`;
      const result = await axios.post(apiUrl, { corrections });
      if (result.status === 200) {
        toast.success(t('correctionsSubmitted'));
      } else {
        toast.error(t('correctionsSubmissionFailed'));
      }
    } catch (error) {
      toast.error(t('correctionsSubmissionFailed'));
    }
  };

  return (
    <div className='panel'>
      <div className="webcam-container">
        <h1 className='blue scannertxt'>{t('aianalytics')}</h1>
        <Webcam
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/png"
          style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
          videoConstraints={videoConstraints}
          className="webcam"
        />
        <CameraInterface /> 
        <div className={`bottom-panel ${isUploading ? 'uploading' : ''}`}>
          <Link className="top-right-button" to='/app/tips'>
            {t('moreinfo')}
          </Link>
          <button 
            className="gallery-upload-button"
            onClick={() => document.getElementById('file-upload').click()}
            disabled={isUploading || showPopup}
          >
            <IonIcon name="images-outline" className="upload-icon" />
          </button>
          <button
            onClick={captureImage}
            disabled={isUploading || showPopup}
            className="capture-button"
          >
            {isUploading ? <LoadingDots /> : <IonIcon name='camera-outline' />}
          </button>
          <input
            type="file"
            id="file-upload"
            accept="image/*"
            onChange={handleFileChange}
            disabled={isUploading || showPopup}
            className="gallery-upload-input"
          />
        </div>
      </div>

      {showPopup && (
        <AnalysisPopup
          className="pop-up"
          analysisResult={analysisResult}
          question={question}
          onResponseSubmit={handleResponseSubmit}
          onCorrectionSubmit={handleCorrectionSubmit}
          onClose={closePopup}
        />
      )}
    </div>
  );
};

export default ImageAnalyzer;
