import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import IonIcon from '@reacticons/ionicons';
import "./AddQuickShortcut.css";
import { useTranslation } from "react-i18next";
import { useUser } from "./UserContext"; // Changed this import

const AddQuickShortcut = ({ isMobileDevice }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user } = useUser(); // Changed this line

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const handleOptionClick = (optionCallback) => {
    localStorage.setItem("hasSeenWelcome", "true");
    optionCallback();
    window.location.reload();
  };

  const options = [
    { 
      label: t('addMeal'), 
      icon: "restaurant-outline",
      onClick: () => {
        if (user) {
          handleOptionClick(() => navigate("/app/meals?showAddMeal=true"));
        } else {
          handleOptionClick(() => navigate("/app/login", { state: { from: "/app/meals?showAddMeal=true" } }));
        }
      }
    },
    { 
      label: t('addProductBtn'), 
      icon: "add-circle-outline",
      onClick: () => handleOptionClick(() => navigate("/app/calc"))
    },
    // Only show scanner option on mobile devices
    ...(isMobileDevice ? [{
      label: t('scanBarcode'), 
      icon: "scan-outline",
      onClick: () => handleOptionClick(() => navigate("/app/scanner"))
    }] : []),
    { 
      label: t('search'), 
      icon: "search-outline",
      onClick: () => handleOptionClick(() => navigate("/app/search"))
    },
  ];

  return (
    <div className="add-quick-shortcut" onClick={toggleExpand}>
      <IonIcon 
        className="ion" 
        name={isExpanded ? "close-outline" : "add-outline"} 
        size={20} 
        color="blue"
      />
      {isExpanded && (
        <div className="options-container">
          {options.map((option, index) => (
            <div
              key={index}
              className="option"
              style={{ animationDelay: `${index * 0.1}s` }}
              onClick={(e) => {
                e.stopPropagation();
                option.onClick();
                setIsExpanded(false);
              }}
            >
              <IonIcon name={option.icon} style={{ marginRight: '8px' }} />
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AddQuickShortcut;
