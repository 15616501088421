import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import './App.css';
import { SafeArea } from '@capacitor-community/safe-area';
import { Capacitor } from "@capacitor/core";  // Add this import at the top

import { UserProvider, useUser } from './UserContext';
import SearchView from './SearchView';
import ListView from './ListView';
import Scanner from './Scanner';
import AddProductUser from './AddProductUser';
import Home from './Home';
import logoImage from './logo.jpg';
import Calc from './CalcView';
import Login from './LoginPanel';
import Register from './RegisterPanel';
import AddProduct from './AddProduct';
import ManageUsers from './ManageUsers';
import Settings from './Settings';
import ShareMeal from './ShareMeal';
import Terms from './Terms';
import Policy from './Policy';
import ImageAnalyzer from './ImageAnalyzer';
import SharedProductDetails from './SharedProductDetails';
import { useTranslation } from 'react-i18next';
import i18n from './i18n';
import AdminPanel from './AdminPanel';
import IonIcon from '@reacticons/ionicons';
import LandingPage from './LandingPage';
import InternetStatus from './InternetStatus';
import { ToastContainer } from 'react-toastify';
import MealsView from './MealsView';
import MealDetails from './MealDetails';
import { getApiUrl } from './apiUrl';
import AdminApplication from './ModeratorApplicationForm';
import MealInstructions from './MealInstructions';
import { motion } from 'framer-motion';
import WelcomeScreen, { closeWelcome } from './WelcomeScreen';
import BugReport from './BugReport';
import RedirectToNoWWW from './RedirectToNoWWW';
import ProductDetails from './ProductDetails';
import AddQuickShortcut from './AddQuickShortcut';
import { MealProvider } from './MealContext';

const CarbohydrateExchangeCalculator = () => {
  const [language, setLanguage] = useState();
  const [darkMode, setDarkMode] = useState(false);
  const navigate = useNavigate();
  const isiOSDevice = /iPhone|iPod|iPad/.test(navigator.userAgent);
  const apiUrl = getApiUrl();
  
  

    // Funkcja do odczytywania języka z local storage
    const getStoredLanguage = () => {
      return localStorage.getItem('language');
    };
    
  
  useEffect(() => {
    SafeArea.enable({
      config: {
        customColorsForSystemBars: true,
        statusBarColor: '#00000000', // transparent
        statusBarContent: 'light',
        navigationBarColor: '#00000000', // transparent
        navigationBarContent: 'light',
      },
    });

    const storedLanguage = getStoredLanguage();
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    } else {
      const browserLanguage = navigator.language || navigator.userLanguage;
      i18n.changeLanguage(browserLanguage.split('-')[0]);
    }
   document.documentElement.lang = i18n.language;
    const savedMode = localStorage.getItem('themeMode');
    const applyTheme = (mode) => {
      if (mode === 'dark') {
        document.body.classList.add('dark-mode');
      } else if (mode === 'light') {
        document.body.classList.remove('dark-mode');
      } else {
        const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
        if (prefersDark) {
          document.body.classList.add('dark-mode');
        } else {
          document.body.classList.remove('dark-mode');
        }
      }
    };
    applyTheme(savedMode || 'system');
  }, []);

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    setLanguage(lang);
  };

  const refresh = () => {
    window.location.reload();
  };

  
  return (
    <UserProvider>
      <MealProvider>
        <RedirectToNoWWW />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/policy" element={<Policy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/benewmod" element={<AdminApplication/>} />
          <Route path="/product/:productId" element={<SharedProductDetails />} />
          <Route path="/app/*" element={<MainApp isiOSDevice={isiOSDevice} refresh={refresh} />} />
          <Route path="/meal/:mealId/:userId" element={<MealDetails />} />
          <Route path="/share-meal/:mealId" element={<ShareMeal />} />
        </Routes>
      </MealProvider>
    </UserProvider>
  );
};

const MainApp = ({ isiOSDevice, refresh }) => {
  const { logout, user } = useUser();
  const navigate = useNavigate();
  const [fadeIn, setFadeIn] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setFadeIn(false);
    const timeout = setTimeout(() => setFadeIn(true), 50);
    return () => clearTimeout(timeout);
  }, [location]);

  useEffect(() => {
    // Trigger fade-in effect on mount

    if (user) {
      const checkTokenExpiration = () => {
        const token = user.token;
        if (!token) {
          logout();
          navigate('/app/login'); // Ensure user is logged out if no token is found
          return;
        }
      
        const decodedToken = decodeJwt(token);
        if (!decodedToken || !decodedToken.exp) {
          logout();
          navigate('/app/login'); // Invalid token format, log out user
          return;
        }

        const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
        if (decodedToken.exp < currentTime) {
          logout();
          navigate('/app/login'); // Token has expired, log out user
        }
      };

      checkTokenExpiration();

      // Optionally, set up interval to periodically check token expiration
      const interval = setInterval(checkTokenExpiration, 60000); // Check every minute

      return () => clearInterval(interval); // Clean up interval on component unmount
    }
  }, [logout, navigate, user]);

  useEffect(() => {
    // Przewiń do góry przy zmianie lokacji
    const mainContent = document.querySelector('.main-content');
    if (mainContent) {
      mainContent.scrollTop = 0;
    }
  }, [location]);

  const decodeJwt = (token) => {
    try {
      const payloadBase64 = token.split('.')[1];
      return JSON.parse(atob(payloadBase64));
    } catch (error) {
      console.error('Error decoding JWT:', error);
      return null;
    }
  };
  
  const savedMode = localStorage.getItem('themeMode');

  return (
    <div className="app-container">
      <div className="logo">
        {isiOSDevice && (
          <button className="refresh-btn" onClick={refresh}>
            <IonIcon className="refreshicon" name="refresh-outline" />
          </button>
        )}
        <img className="logoimg" src={logoImage} alt="DiabCalc Logo" />
        <p>
          <Link to="/app">DiabCalc</Link>
        </p>
        <Link to="/app/settings" className="settings-btn">
          <IonIcon className="ion" name="settings-outline" size={20} color="white" />
        </Link>
      </div>
      <div className={`main-content ${fadeIn ? 'fade-in' : ''}`}>
        <InternetStatus />
        <WelcomeScreen/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="search" element={<SearchView />} />
          <Route path="listview" element={<ListView />} />
          <Route path="scanner" element={<Scanner />} />
          <Route path="calc" element={<Calc />} />
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route path="add-product" element={<AddProduct />} />
          <Route path="userslist" element={<ManageUsers />} />
          <Route path="settings" element={<Settings />} />
          <Route path="addproductuser" element={<AddProductUser />} />
          <Route path="analyze" element={<ImageAnalyzer />} />
          <Route path="meals" element={<MealsView />} />
          <Route path="meal/:mealid" element={<MealDetails />} />
          <Route path='tips' element={<MealInstructions />} />
          <Route path='adminpanel' element={<AdminPanel />} />
          <Route path="product-details" element={<ProductDetails />} />
          <Route path="product/:id" element={<ProductDetails />} />
        </Routes>
      </div>
      <NavigationBottom motioncomp={motion} />
      <ToastContainer theme={savedMode} />
    </div>
  );
};

const NavigationBottom = (motioncomp) => {
  const { user } = useUser();
  const { t } = useTranslation();
  const [focusedTab, setFocusedTab] = useState('');
  const location = useLocation();
  const navigate = useNavigate(); // Add this line

  const isMobileDevice = Capacitor.isNativePlatform() || 
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ||
    (window.innerWidth <= 1024 && window.innerHeight <= 1024); // Warunek na szerokość ekranu, aby uwzględnić tablety

  const tabBounce = {
    initial: { scale: 1 },
    animate: { scale: 1.1 },
    transition: { type: "spring", stiffness: 300 },
  };

  const setFocusedTabFromLocation = () => {
    let currentPath = location.pathname;
    
    const routesToTabs = {
      '/': 'Home',
      '/search': 'Search',
      '/calc': 'Calc',
      '/scanner': 'Scanner',
      '/login': 'Login',
      '/register': 'Register',
      '/logout': 'Logout',
      '/meal': 'Meal',
      '/listview': 'List',
    };
    
    // Check if the path is exactly /app or contains other segments
    if (currentPath === '/app') {
        setFocusedTab('Home'); // Default tab for /app
        return;
    }
    
    // Remove the /app prefix
    currentPath = currentPath.replace('/app', '');
    
    let tab = routesToTabs[currentPath];
    
    if (!tab) {
        // Handle dynamic paths like /meal/:mealid
        const dynamicRoutes = [
            { path: '/meal', tab: 'Meal' }
        ];
        dynamicRoutes.forEach(route => {
            if (currentPath.startsWith(route.path)) {
                tab = route.tab;
            }
        });
    }
    
    // Special case for Register
    if (tab === 'Register') {
        tab = 'Login';
    }
    
    setFocusedTab(tab || 'Home'); // Default to 'Home' if no match is found
};


  useEffect(() => {
    setFocusedTabFromLocation();
  }, [location]);
  

  const GetIcon = (route) => {
    let iconName;
    if (route === 'Home') {
      iconName = focusedTab === 'Home' ? 'home' : 'home-outline';
   
    } else if (route === 'Search') {
      iconName = focusedTab === 'Search' ? 'search' : 'search-outline';
    } else if (route === 'Calc') {
      iconName = focusedTab === 'Calc' ? 'calculator' : 'calculator-outline';
    } else if (route === 'Scanner') {
      iconName = focusedTab === 'Scanner' ? 'scan' : 'scan-outline';
    } else if (route === 'Login') {
      iconName = focusedTab === 'Login' ? 'person' : 'person-outline';
    } else if (route === 'Meal') {
      iconName = focusedTab === 'Meal' ? 'restaurant' : 'restaurant-outline';
    }else if (route === 'List') {
      iconName = focusedTab === 'List' ? 'bookmark' : 'bookmark-outline';
    }
  
    return iconName;
  };

  const GetCSS = (route) => {
    let cssname;
    if (route === 'Home') {
      cssname = focusedTab === 'Home' ? 'nav-link activeclass' : 'nav-link';
   
    } else if (route === 'Search') {
      cssname = focusedTab === 'Search' ? 'nav-link activeclass' : 'nav-link';
    } else if (route === 'Calc') {
      cssname = focusedTab === 'Calc' ? 'nav-link activeclass' : 'nav-link';
    } else if (route === 'Scanner') {
      cssname = focusedTab === 'Scanner' ? 'nav-link activeclass' : 'nav-link';
    } else if (route === 'Login') {
      cssname = focusedTab === 'Login' ? 'nav-link activeclass' : 'nav-link';
    } else if (route === 'Meal') {
      cssname = focusedTab === 'Meal' ? 'nav-link activeclass' : 'nav-link';
    }else if (route === 'List') {
      cssname = focusedTab === 'List' ? 'nav-link activeclass' : 'nav-link';
    }
  
    return cssname;
  };

  const handleNavClick = (e, path) => {
    const hasSeenWelcome = localStorage.getItem("hasSeenWelcome");
    if (!hasSeenWelcome) {
      localStorage.setItem("hasSeenWelcome", "true");
      window.location.reload();
      return;
    }
    navigate(path);
  };

  return (
    <nav className="bottom-nav">
      <NavLink to="/app/" className={GetCSS('Home')} onClick={(e) => handleNavClick(e, '/app/')}>
        <motion.div
          variants={tabBounce}
          initial="initial"
          animate={focusedTab === 'Home' ? "animate" : "initial"}
        >
          <IonIcon className="ion" name={GetIcon('Home')} size={20} color="blue" />
        </motion.div>
      </NavLink>
      
      <NavLink to="/app/search" className={GetCSS('Search')} onClick={(e) => handleNavClick(e, '/app/search')}>
        <motion.div
          variants={tabBounce}
          initial="initial"
          animate={focusedTab === 'Search' ? "animate" : "initial"}
        >
          <IonIcon className="ion" name={GetIcon('Search')} size={20} color="blue" />
        </motion.div>
      </NavLink>

      <div className="quick-shortcut-container">
        <AddQuickShortcut isMobileDevice={isMobileDevice} />
      </div>

      <NavLink to="/app/calc" className={GetCSS('Calc')} onClick={(e) => handleNavClick(e, '/app/calc')}>
        <motion.div
          variants={tabBounce}
          initial="initial"
          animate={focusedTab === 'Calc' ? "animate" : "initial"}
        >
          <IonIcon className="ion" name={GetIcon('Calc')} size={20} color="blue" />
        </motion.div>
      </NavLink>

      {isMobileDevice && (
        <NavLink to="/app/scanner" className={`scanner ${GetCSS('Scanner')}`} onClick={(e) => handleNavClick(e, '/app/scanner')}>
          <motion.div
            variants={tabBounce}
            initial="initial"
            animate={focusedTab === 'Scanner' ? "animate" : "initial"}
          >
            <IonIcon className="ion" name={GetIcon('Scanner')} size={20} color="blue" />
          </motion.div>
        </NavLink>
      )}

      {user ? (
        <NavLink to="/app/meals" className={GetCSS('Meal')} onClick={(e) => handleNavClick(e, '/app/meals')}>
          <motion.div
            variants={tabBounce}
            initial="initial"
            animate={focusedTab === 'List' ? "animate" : "initial"}
          >
            <IonIcon className="ion" name={GetIcon('Meal')} size={20} color="blue" />
          </motion.div>
        </NavLink>
      ) : (
        <NavLink to="/app/login" className={GetCSS('Login')} onClick={(e) => handleNavClick(e, '/app/login')}>
          <motion.div
            variants={tabBounce}
            initial="initial"
            animate={focusedTab === 'Login' ? "animate" : "initial"}
          >
            <IonIcon className="ion" name={GetIcon('Login')} size={20} color="blue" />
          </motion.div>
        </NavLink>
      )}
    </nav>
  );
};

export default CarbohydrateExchangeCalculator;
