import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useUser } from './UserContext';
import IonIcon from '@reacticons/ionicons';
import { PieChart, Pie, Cell, Tooltip, LabelList } from 'recharts';
import './MealDetails.css';
import AddProductPopup from './AddProductMealPopup';
import { getApiUrl } from './apiUrl';
import './variables.css';
import AIModifyMealPopup from './AIModifyMealPopup';
import Loading from './Loading';
import { useMeal } from './MealContext';
import { toast } from 'react-toastify';

const COLORS = ['#FF8042', '#0088FE', '#00C49F'];

const LoadingDots = () => (
  <div className="loading-dots">
    <div className="dot"></div>
    <div className="dot"></div>
    <div className="dot"></div>
  </div>
);

const MealDetails = () => {
  const { t } = useTranslation();
  const { mealid } = useParams();
  const { user } = useUser();
  const apiUrl = getApiUrl();
  const navigate = useNavigate();
  const { setCurrentMeal } = useMeal();

  const [meal, setMeal] = useState(null);
  const [products, setProducts] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isAIModifyPopupVisible, setIsAIModifyPopupVisible] = useState(false);
  const [recipe, setRecipe] = useState(null);
  const [isRecipeVisible, setIsRecipeVisible] = useState(false);
  const [isGeneratingRecipe, setIsGeneratingRecipe] = useState(false);
  const [editingWeight, setEditingWeight] = useState({});
  const [customPortions, setCustomPortions] = useState({});
  const [customMealWeight, setCustomMealWeight] = useState('');
  const [hoveredData, setHoveredData] = useState(null);
  const [tempProductWeights, setTempProductWeights] = useState({});
  const [searchResults, setSearchResults] = useState([]);
  const [isSharePopupVisible, setIsSharePopupVisible] = useState(false);

  const handleMouseEnter = (data) => {
    if (hoveredData?.name !== data.name) {
      setHoveredData(data);
    }
  };
  
  const handleMouseLeave = () => {
    setHoveredData(null);
  };

  const fetchMealDetails = useCallback(async () => {
    setLoading(true);
    try {
      if (!user || !user.token) {
        toast.error(t('mustBeLoggedIn'));
        navigate('/app/login');
        return;
      }

      const response = await axios.get(`${apiUrl}meal/${mealid}/${user.userId}`, {
        headers: {
          'Authorization': `Bearer ${user.token}`,
          'Content-Type': 'application/json',
        }
      });

      if (response.data.success) {
        setMeal(response.data.meal);
        setProducts(response.data.products);
        setRecipe(response.data.meal.instructions);
        // Dodaj ustawienie wagi posiłku
        if (response.data.meal.weight) {
          setCustomMealWeight(response.data.meal.weight.toString());
        } else {
          const totalWeight = response.data.products.reduce((sum, product) => 
            sum + (product.grams || 0), 0);
          setCustomMealWeight(totalWeight.toString());
        }
      } else {
        throw new Error(response.data.message || 'Failed to fetch meal details');
      }
    } catch (error) {
      console.error('Error fetching meal details:', error);
      toast.error(t('errorFetchingMeal'));
      navigate('/app/meals');
    } finally {
      setLoading(false);
    }
  }, [mealid, user, apiUrl, navigate, t]);

  useEffect(() => {
    fetchMealDetails();
  }, [fetchMealDetails]);

  useEffect(() => {
    if (products.length > 0 && meal) {
      const updatedWW = calculateTotalWW(products);
      const updatedFPU = calculateTotalFPU(products);
      
      if (meal.ww !== updatedWW || meal.fpu !== updatedFPU) {
        setMeal(prevMeal => ({
          ...prevMeal,
          ww: updatedWW,
          fpu: updatedFPU
        }));
      }
    }
  }, [products]); // Remove meal from dependencies

  // Remove separate updateMealWW and updateMealFPU effects
  
  useEffect(() => {
    if (isRecipeVisible && !recipe) {
      generateRecipe();
    }
  }, [isRecipeVisible, recipe]);

  useEffect(() => {
    if (meal && mealid) {
      const mealData = {
        id: parseInt(mealid),
        name: meal.mealname,
        lastAccessed: new Date().toISOString()
      };
      setCurrentMeal(mealData);
    }
    // Usuwamy return () => setCurrentMeal(null);
  }, [mealid, meal?.mealname, setCurrentMeal]);

  const calculateTotalWeight = (products) => {
    return products.reduce((total, product) => {
      const weight = product.grams;
      return total + (isNaN(weight) ? 0 : weight);
    }, 0);
  };

  const toggleEditMode = () => {
    setEditMode((prev) => !prev);
  };

  const deleteProduct = async (productId) => {
    try {
      const token = user.token;
      const response = await axios.delete(`${apiUrl}removeProduct`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        data: {
          mealid,
          userid: user.userId,
          productId,
        },
      });

      if (response.data.success) {
        setProducts((prevProducts) => prevProducts.filter(product => product.id !== productId));
        updateMealWW(products);
      } else {
        console.error('Failed to delete product:', response.data.message);
      }
    } catch (error) {
      console.error('An error occurred during communication with the server:', error);
    }
  };

  const showAddProductMenu = () => {
    setIsPopupVisible(true);
  };

  const closePopup = () => {
    setIsPopupVisible(false);
  };

  const showAIModifyMenu = () => {
    setIsAIModifyPopupVisible(true);
  };

  const closeAIModifyPopup = () => {
    setIsAIModifyPopupVisible(false);
  };

  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, value, name }) => {
    const RADIAN = Math.PI / 180;
  
    const radius = outerRadius + 10; // Adjust the distance from the center
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
  
    // Ensure x and y are valid numbers
    if (isNaN(x) || isNaN(y)) {
      return null;
    }
  
    // Get the CSS variable values
    const labelColor = getComputedStyle(document.documentElement).getPropertyValue('--text-color').trim();

  
    return (
      <text x={x} y={y} fill="#099dff" fontSize={15} fontWeight="bold" textAnchor="center">
        {`${name}: ${value}`}
      </text>
    );
  };
  

  useEffect(() => {
    const pieData = products.map(product => {
      const nutriments = JSON.parse(product.json).nutriments;
      const carbs = nutriments.carbohydrates_100g ? nutriments.carbohydrates_100g * (product.grams / 100) : null;
      const proteins = nutriments.proteins_100g ? nutriments.proteins_100g * (product.grams / 100) : null;
      const fats = nutriments.fats_100g ? nutriments.fats_100g * (product.grams / 100) : null;
      return {
        carbohydrates: carbs,
        proteins: proteins,
        fats: fats,
      };
    });

    const totalNutrients = pieData.reduce((acc, curr) => ({
      carbohydrates: (acc.carbohydrates || 0) + (curr.carbohydrates || 0),
      proteins: (acc.proteins || 0) + (curr.proteins || 0),
      fats: (acc.fats || 0) + (curr.fats || 0),
    }), { carbohydrates: 0, proteins: 0, fats: 0 });

    const newChartData = [
      { name: t('carbs'), value: totalNutrients.carbohydrates },
      { name: t('proteins'), value: totalNutrients.proteins },
      { name: t('fats'), value: totalNutrients.fats },
    ].filter(item => item.value !== null && item.value > 0);

    setChartData(newChartData);
  }, [products]);

  if (loading) {
    return <Loading/>;
  }

  if (!meal) {
    return <div>{t('mealNotFound')}</div>;
  }
  const calculateFPU = (protein, fat) => {
    if (isNaN(protein) || isNaN(fat)) return '0.0';
    let multipliedProtein = protein * 4;
    let multipliedFat = fat * 9;
    return parseFloat((multipliedProtein + multipliedFat) / 100).toFixed(1);
  };

  const calculateCU = (carbohydrates) => {
    if (isNaN(carbohydrates)) return '0.0';
    return parseFloat((carbohydrates / 10).toFixed(1));
  };

  const totalWeight = calculateTotalWeight(products);
  const calculateTotalWW = (products) => {
    if (!products || products.length === 0) return '0.0';
    return products.reduce((total, product) => {
      const productData = JSON.parse(product.json);
      const carbs = productData.nutriments.carbohydrates_100g * (product.grams / 100);
      return total + (isNaN(carbs) ? 0 : carbs / 10);
    }, 0).toFixed(1);
  };
  
  const updateMealWW = (products) => {
    const updatedWW = products.length > 0 ? calculateTotalWW(products) : '?';
    setMeal((prevMeal) => ({ ...prevMeal, ww: updatedWW }));
  };
  
  const calculateTotalFPU = (products) => {
    return products.reduce((total, product) => {
      const productData = JSON.parse(product.json);
      const protein = productData.nutriments.proteins_100g * (product.grams / 100);
      const fat = productData.nutriments.fats_100g * (product.grams / 100);
      if (isNaN(protein) || isNaN(fat)) return total;
      const fpu = (protein * 4 + fat * 9) / 100;
      return total + (isNaN(fpu) ? 0 : fpu);
    }, 0).toFixed(1);
  };
  
  const updateMealFPU = (products) => {
    const updatedFPU = products.length > 0 ? calculateTotalFPU(products) : '?';
    setMeal((prevMeal) => ({ ...prevMeal, fpu: updatedFPU }));
  };
  
  const generateRecipe = async () => {
    if (!recipe && isRecipeVisible) {
      setIsGeneratingRecipe(true);
      const token = user.token;
      const newRecipe = await axios.post(`${apiUrl}generateRecipe`, { userId: user.userId, mealId: mealid }, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      fetchMealDetails();
      setIsGeneratingRecipe(false);
    }
  };

  const updateProductWeight = async (productId, newWeight) => {
    try {
      const token = user.token;
      const response = await axios.patch(`${apiUrl}updateProductWeight`, {
        mealId: mealid,
        userId: user.userId,
        productId,
        newWeight: parseFloat(newWeight)
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.data.success) {
        setProducts(prevProducts =>
          prevProducts.map(product =>
            product.id === productId
              ? { ...product, grams: parseFloat(newWeight) }
              : product
          )
        );
        setTempProductWeights(prev => ({ ...prev, [productId]: undefined }));
        toast.success(t('weightUpdated'));
      }
    } catch (error) {
      console.error('Error updating product weight:', error);
      toast.error(t('errorUpdatingWeight'));
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  const totalValue = chartData.reduce((sum, data) => sum + data.value, 0);

  // Add percentage to chartData
  const chartDataWithPercentage = chartData.map(data => ({
    ...data,
    percentage: ((data.value / totalValue) * 100).toFixed(2),
  }));
  
  const calculateNutrientsPer100g = (products, totalWeight) => {
    if (!products || products.length === 0 || totalWeight <= 0) {
      return {
        carbs: 0,
        proteins: 0,
        fats: 0,
        ww: 0,
        fpu: 0
      };
    }
  
    const totals = products.reduce((acc, product) => {
      const nutriments = JSON.parse(product.json).nutriments;
      const weight = product.grams;
      
      return {
        carbs: acc.carbs + (nutriments.carbohydrates_100g ? (nutriments.carbohydrates_100g * weight / 100) : 0),
        proteins: acc.proteins + (nutriments.proteins_100g ? (nutriments.proteins_100g * weight / 100) : 0),
        fats: acc.fats + (nutriments.fats_100g ? (nutriments.fats_100g * weight / 100) : 0)
      };
    }, { carbs: 0, proteins: 0, fats: 0 });
  
    // Calculate per 100g values using proportion
    return {
      carbs: ((totals.carbs * 100) / totalWeight).toFixed(1),
      proteins: ((totals.proteins * 100) / totalWeight).toFixed(1),
      fats: ((totals.fats * 100) / totalWeight).toFixed(1),
      ww: ((totals.carbs * 10) / totalWeight).toFixed(1), // WW per 100g
      fpu: ((totals.proteins * 4 + totals.fats * 9) / totalWeight).toFixed(1) // FPU per 100g
    };
  };

  const calculateNutrientsForWeight = (products, weight) => {
    if (!weight || isNaN(weight) || products.length === 0) {
      return { carbs: '-', proteins: '-', fats: '-', ww: '-', fpu: '-' };
    }
  
    const ratio = weight / totalWeight;
    const totals = products.reduce((acc, product) => {
      const nutriments = JSON.parse(product.json).nutriments;
      return {
        carbs: acc.carbs + (nutriments.carbohydrates_100g ? (nutriments.carbohydrates_100g * product.grams / 100) : 0),
        proteins: acc.proteins + (nutriments.proteins_100g ? (nutriments.proteins_100g * product.grams / 100) : 0),
        fats: acc.fats + (nutriments.fats_100g ? (nutriments.fats_100g * product.grams / 100) : 0)
      };
    }, { carbs: 0, proteins: 0, fats: 0 });
  
    return {
      carbs: (totals.carbs * ratio).toFixed(1),
      proteins: (totals.proteins * ratio).toFixed(1),
      fats: (totals.fats * ratio).toFixed(1),
      ww: ((totals.carbs * ratio) / 10).toFixed(1),
      fpu: ((totals.proteins * ratio * 4 + totals.fats * ratio * 9) / 100).toFixed(1)
    };
  };

  // Dodaj nową funkcję do komponentu
const updateMealWeight = async (newWeight) => {
  try {
    const response = await axios.patch(`${apiUrl}updateMealWeight`, {
      mealId: mealid,
      userId: user.userId,
      newWeight: parseFloat(newWeight)
    }, {
      headers: {
        'Authorization': `Bearer ${user.token}`,
        'Content-Type': 'application/json',
      },
    });

    if (response.data.success) {
      toast.success(t('weightUpdated'));
      fetchMealDetails(); // Odśwież dane posiłku
    } else {
      toast.error(t('errorUpdatingWeight'));
    }
  } catch (error) {
    console.error('Error updating meal weight:', error);
    toast.error(t('errorUpdatingWeight'));
  }
};

const handleClick = (data) => {
  if (hoveredData?.name !== data.name) {
    setHoveredData(data);
  }
};

// Funkcja do otwierania popupu udostępniania
const showSharePopup = () => {
  setIsSharePopupVisible(true);
};

const closeSharePopup = () => {
  setIsSharePopupVisible(false);
};

// Dodaj nową funkcję do generowania PDF
const generatePDF = () => {
  // Logika generowania PDF
  console.log("Generowanie PDF z danymi posiłku...");
};

// Dodaj nową funkcję do udostępniania linku
const shareMealLink = () => {
  const shareLink = `${window.location.origin}/share-meal/${mealid}`;
  navigator.clipboard.writeText(shareLink);
  toast.success(t('linkCopied'));
};

  return (
    <div className='panel'>
      
      <div className="modal">
        <header className="meal-details-header">
          <div className="header-left">
            <button className="back-button" onClick={handleBack}>
              <IonIcon name="arrow-back-outline" size={24} />
            </button>
            <h1 className='title'>{meal.mealname}</h1>
          </div>
          <div className="header-buttons">
            <button className="edit-button" onClick={toggleEditMode}>
             <p><IonIcon name="create-outline" size={24} /> {t('edit')}</p> 
            </button>
            <button className="ai-modify-button" onClick={showAIModifyMenu}>
            <p>  <IonIcon name="analytics-outline" size={24} /> {t('modify')}</p>
            </button>
            {/* <button className="share-button" onClick={showSharePopup}>
              {t('share')}
            </button> */}
          </div>
        </header>
        
        <div className="meal-summary">
        {editMode && (
          <button className="add-product-button" onClick={showAddProductMenu}>
            {t('addproductinfo')}
          </button>
        )} 
        
       

        </div>
        
        <div className="meal-details-info">
       
        <div className="meal-details-chart">
      {/* Pie chart */}
      <PieChart width={300} height={300}>
        <Pie
          data={chartDataWithPercentage}
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="50%"
          innerRadius={60}
          outerRadius={100}
          fill="#8884d8"
          paddingAngle={5}
        >
          {chartDataWithPercentage.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>

      {/* Legend */}
      <div style={{ marginLeft: '20px' }}>
        {chartDataWithPercentage.map((entry, index) => (
          <div key={`legend-${index}`} style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
            <div
              style={{
                width: '20px',
                height: '20px',
                backgroundColor: COLORS[index % COLORS.length],
                marginRight: '10px',
              }}
            ></div>
            <span>{entry.name}: {entry.percentage}%</span>
          </div>
        ))}
      </div>
    </div>
        <div/>
        {/* Add new summary table */}
        <div className="meal-summary-table">
  <table>
    <thead>
      <tr>
        <th>{t('nutrients')}</th>
        <th>{t('per') + " "+ totalWeight}g</th>
        <th>{t('per100g')}</th>
        <th>{t('calculator')}</th>
      </tr>
    </thead>
    <tbody>
      {(() => {
        const per100g = calculateNutrientsPer100g(products, totalWeight);
        const customCalc = calculateNutrientsForWeight(products, customMealWeight);
        return (
          <>
            <tr>
              <td>{t('mealWeight')}</td>
              <td>{totalWeight}g</td>
              <td>100g</td>
              <td>
                <input
                  type="number"
                  placeholder={t('enterWeight')}
                  value={customMealWeight}
                  onChange={(e) => {
                    setCustomMealWeight(e.target.value);
                    // Możesz dodać debounce jeśli chcesz opóźnić zapisywanie
                  }}
                  onBlur={(e) => updateMealWeight(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      updateMealWeight(e.target.value);
                    }
                  }}
                  className="weight-input"
                />
              </td>
            </tr>
            <tr>
              <td>{t('carbs')}</td>
              <td>{products.reduce((total, product) => {
                const nutriments = JSON.parse(product.json).nutriments;
                return total + (nutriments.carbohydrates_100g ? (nutriments.carbohydrates_100g * (product.grams / 100)) : 0);
              }, 0).toFixed(1)}g</td>
              <td>{per100g.carbs}g</td>
              <td>{customCalc.carbs}g</td>
            </tr>
            <tr>
              <td>{t('proteins')}</td>
              <td>{products.reduce((total, product) => {
                const nutriments = JSON.parse(product.json).nutriments;
                return total + (nutriments.proteins_100g ? (nutriments.proteins_100g * (product.grams / 100)) : 0);
              }, 0).toFixed(1)}g</td>
              <td>{per100g.proteins}g</td>
              <td>{customCalc.proteins}g</td>
            </tr>
            <tr>
              <td>{t('fats')}</td>
              <td>{products.reduce((total, product) => {
                const nutriments = JSON.parse(product.json).nutriments;
                return total + (nutriments.fats_100g ? (nutriments.fats_100g * (product.grams / 100)) : 0);
              }, 0).toFixed(1)}g</td>
              <td>{per100g.fats}g</td>
              <td>{customCalc.fats}g</td>
            </tr>
            <tr>
              <td><IonIcon name='flash' className='text' size={20} /> {t('WW')}</td>
              <td>{calculateTotalWW(products)}</td>
              <td>{per100g.ww}</td>
              <td>{customCalc.ww}</td>
            </tr>
            <tr>
              <td><IonIcon name='flash-outline' className='text' size={20} /> {t('fpu')}</td>
              <td>{calculateTotalFPU(products)}</td>
              <td>{per100g.fpu}</td>
              <td>{customCalc.fpu}</td>
            </tr>
          </>
        );
      })()}
    </tbody>
  </table>
</div>

      

        <div className="recipe-section">
        <button 
            onClick={() => setIsRecipeVisible(prev => !prev)} 
            className={isGeneratingRecipe ? 'disabled-button' : ''}
            disabled={isGeneratingRecipe}
        >
            {isRecipeVisible ? t('hideRecipe') : t('showRecipe')}
        </button>
        {isRecipeVisible && (
            <div className="recipe-content">
                <h2>{t('recipe')}</h2>
                <p>{t('AIdisc')}</p>
                {isGeneratingRecipe ? (
                      <LoadingDots/>
                ) : (
                    meal.instructions ? (
                        <pre>{meal.instructions}</pre>
                    ) : (
                        <div>
                            <div className='loading'>
                              <LoadingDots/>
                            </div>
                        </div>
                    )
                )}
            </div>
        )}
    </div>
        </div>
        <br></br>
        <section className="meal-details-products">
  <h2>{t('products')}</h2>
  {products.length > 0 ? (
    products.map((product) => {
      const nutriments = JSON.parse(product.json).nutriments; // Przypisz nutriments
      return (
        <div key={product.id} className={`product-item-dt pop-up ${editMode ? 'draggable' : ''}`}>
          <h2 className="product-name-dt">{JSON.parse(product.json).product_name}</h2>
          <table className="product-info-table">
            <thead>
              <tr>
                <th>{t('nutrients')}</th>
                <th>{t('per100g')}</th>
                <th>{t('per') + " " + product.grams}g </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{t('amountOfGrams')}:</td>
                <td>100g</td>
                <td>
                  {editMode ? (
                    <div className="weight-edit-container">
                      <input
                        type="number"
                        value={tempProductWeights[product.id] !== undefined ? tempProductWeights[product.id] : product.grams}
                        min="0"
                        onChange={(e) => setTempProductWeights(prev => ({
                          ...prev,
                          [product.id]: e.target.value
                        }))}
                        onBlur={(e) => {
                          if (tempProductWeights[product.id] !== undefined) {
                            updateProductWeight(product.id, tempProductWeights[product.id]);
                          }
                        }}
                        onKeyPress={(e) => {
                          if (e.key === 'Enter') {
                            e.target.blur();
                          }
                        }}
                        className="weight-input"
                      />
                      <span>g</span>
                    </div>
                  ) : (
                    <span>{product.grams}g</span>
                  )}
                </td>
              </tr>
              <tr>
                <td>{t('carbs')}:</td>
                <td>{nutriments.carbohydrates_100g ? Number(nutriments.carbohydrates_100g).toFixed(1) : '-'}g</td>
                <td>{nutriments.carbohydrates_100g ? (nutriments.carbohydrates_100g * (product.grams / 100)).toFixed(1) : '-'}g</td>
              </tr>
              <tr>
                <td>{t('proteins')}:</td>
                <td>{nutriments.proteins_100g ? Number(nutriments.proteins_100g).toFixed(1) : '-'}g</td>
                <td>{nutriments.proteins_100g ? (nutriments.proteins_100g * (product.grams / 100)).toFixed(1) : '-'}g</td>
              </tr>
              <tr>
                <td>{t('fats')}:</td>
                <td>{nutriments.fats_100g ? Number(nutriments.fats_100g).toFixed(1) : '-'}g</td>
                <td>{nutriments.fats_100g ? (nutriments.fats_100g * (product.grams / 100)).toFixed(1) : '-'}g</td>
              </tr>
              <tr>
                <td><IonIcon name='flash' className='text' size={20} /> {t('WW')}</td>
                <td>{calculateCU(nutriments.carbohydrates_100g)}</td>
                <td>{calculateCU(nutriments.carbohydrates_100g * (product.grams / 100))}</td>
              </tr>
              <tr>
                <td><IonIcon name='flash-outline' className='text' size={20} /> {t('fpu')}</td>
                <td>{calculateFPU(nutriments.proteins_100g, nutriments.fats_100g)}</td>
                <td>{calculateFPU(
                  nutriments.proteins_100g * (product.grams / 100),
                  nutriments.fats_100g * (product.grams / 100)
                )}</td>
              </tr>
            </tbody>
          </table>
          {editMode && (
            <button className="delete-product-button" onClick={() => deleteProduct(product.id)}>
              {t('delete')}
            </button>
          )}
        </div>
      );
    })
  ) : (
    <div className="empty-meal-container">
      <IonIcon name="add-circle-outline" size={64} className="empty-meal-icon" />
      <p>{t('noProducts')}</p>
      <button className="add-product-button-large" onClick={showAddProductMenu}>
        {t('addFirstProduct')}
      </button>
    </div>
  )}
</section>


   
       
        
      </div> 
      <AddProductPopup 
        isVisible={isPopupVisible} 
        user={user} 
        onClose={closePopup} 
        currentMealId={mealid}  
      />
      <AIModifyMealPopup 
          isVisible={isAIModifyPopupVisible} 
          onClose={closeAIModifyPopup}
          meal={meal}
          products={products}
          mealId={mealid}
          onMealUpdate={fetchMealDetails}
          user={user}
        />
      {isSharePopupVisible && (
        <div className='popup-overlay pop-up'>
        <div className="popup-container">
          <h2>{t('shareMeal')}</h2>
          <p>{t('shareMealDescription')}</p>
          {/* <button onClick={generatePDF}>{t('shareAsPDF')}</button> */}
          <button onClick={shareMealLink}>{t('copyLink')}</button>
          <button onClick={closeSharePopup}>{t('close')}</button>
        </div>
        </div>
      )}
    </div>
  );
};

export default MealDetails;


